<template>
    <img
        v-if="tenantConfig?.logoDefault"
        :src="tenantConfig.logoDefault.url"
        alt="Logo"
        class="block dark:hidden m-0"
        :class="[{
            'dark:hidden': tenantConfig.logoDark,
        }, props.logoClass]"
    />

    <img
        v-if="tenantConfig?.logoDark"
        :src="tenantConfig.logoDark.url"
        alt="Logo"
        class="hidden dark:block m-0"
        :class="[{
            'hidden dark:block': tenantConfig.logoDefault,
            'block': !tenantConfig.logoDefault,
        }, props.logoClass]"
    />
</template>

<script setup lang="ts">
    import type { TenantType } from '~/generated/types';

    const tenantConfig = useState<TenantType | undefined>('tenantConfig');

    export interface Props {
        logoClass?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        logoClass: undefined,
    });
</script>
